import styles from './utils/styles';

const colors = {
  accordion: {
    background: '#e8e8e8',
    backgroundHover: '#d1d1d1',
  },
  accountMenuBackground: 'white',
  accountMenuItemBorder: styles.colors.gray[100],
  accountMenuItemCurrentBackground: styles.colors.gray[100],

  background: 'white',
  badge: styles.colors.blue[600],
  border: styles.colors.gray[100],
  brandPrimary: styles.colors.blue[500],
  brandSecondary: styles.colors.orange[500],
  breadcrumbLinkColor: styles.colors.gray[500],
  cardBackground: 'white',
  cardText: styles.colors.gray[800],
  contentCard: {
    primary: {
      backgroundColor: styles.colors.blue[600],
      color: '#FFFFFF',
      border: styles.colors.gray[100],
    },
    secondary: {
      backgroundColor: styles.colors.gray[100],
      color: styles.colors.gray[800],
      border: styles.colors.gray[100],
    },
    tertiary: {
      backgroundColor: styles.colors.gray[400],
      color: '#FFFFFF',
      border: styles.colors.gray[100],
    },
    quaternary: {
      backgroundColor: 'transparent',
      color: styles.colors.gray[800],
      border: styles.colors.gray[100],
    },
  },
  headerBackground: 'white',
  iconButtonFill: styles.colors.gray[700],
  iconButtonBackgroundHover: styles.colors.gray[200],
  inputBorder: styles.colors.gray[500],
  inputFontColor: styles.colors.gray[500],
  link: styles.colors.blue[500],
  linkHover: styles.colors.blue[600],
  searchBorder: styles.colors.gray[200],
  searchInput: styles.colors.gray[500],
  tagBackground: styles.colors.gray[100],
  tagBackgroundHover: styles.colors.gray[200],
  navSearchAllResultsColor: styles.colors.blue[500],
  navSearchHitsContainerText: styles.colors.gray[800],
  searchFormHitBorderColor: styles.colors.gray[200],
  typography: {
    brandContrast: '#ffffff',
  },
};

export default colors;
