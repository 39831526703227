import React, { createContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import getCookieDomain from '@rsc/utils/getCookieDomain';

const CURRENCY_COOKIE = 'currency';
const COUNTRY_CONFIRMED_COOKIE = 'country_confirmed';

// create locale context to store currency
const LocaleContext = createContext();

const isBrowser = typeof window !== 'undefined';

const getActiveCurrency = allowedCurrencies => {
  if (isBrowser) {
    const urlParams = new URLSearchParams(window.location.search);
    const queryCurrency = urlParams.get(CURRENCY_COOKIE);
    const storedCurrency = Cookies.get(CURRENCY_COOKIE);

    if (
      queryCurrency &&
      allowedCurrencies.includes(queryCurrency.toUpperCase())
    ) {
      return queryCurrency.toUpperCase();
    }

    if (storedCurrency) {
      // make sure stored currency is allowed
      const initialCurrency = allowedCurrencies.includes(
        storedCurrency?.toUpperCase()
      )
        ? storedCurrency.toUpperCase()
        : 'USD';

      return initialCurrency;
    }
  }

  return 'USD';
};

const hasCountryCookie = () => {
  if (isBrowser) {
    const currency = Cookies.get(CURRENCY_COOKIE);

    if (currency === 'USD') {
      return true;
    }

    const countryConfirmed = Cookies.get(COUNTRY_CONFIRMED_COOKIE);

    return countryConfirmed === 'true';
  }

  return false;
};

const setCountryCookie = () => {
  if (isBrowser) {
    Cookies.set(COUNTRY_CONFIRMED_COOKIE, 'true', {
      expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
      domain: getCookieDomain(),
    });
  }
};

// create locale provider to store currency
function LocaleProvider({ children, allowedCurrencies }) {
  // set default currency
  const [currency, setCurrency] = useState(
    getActiveCurrency(allowedCurrencies)
  );

  const [countryPickerActive, setCountryPickerActive] = useState(false);

  useEffect(() => {
    if (!hasCountryCookie()) {
      setCountryPickerActive(true);
    }
  }, []);

  const updateCurrency = newCurrency => {
    if (allowedCurrencies.includes(newCurrency?.toUpperCase())) {
      setCurrency(newCurrency.toUpperCase());
    }
  };

  const getCountryCode = () => {
    let countryCode = 'US';

    if (currency === 'CAD') {
      countryCode = 'CA';
    }

    return countryCode;
  };

  useEffect(() => {
    setCurrency(getActiveCurrency(allowedCurrencies));
  }, []);

  // write currency to browser storage
  useEffect(() => {
    Cookies.set(CURRENCY_COOKIE, currency, {
      expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
      domain: getCookieDomain(),
    });
  }, [currency]);

  const memoizedValue = useMemo(
    () => ({
      currency,
      updateCurrency,
      getCountryCode,
      countryPickerActive,
      setCountryPickerActive,
      setCountryCookie,
    }),
    [currency, countryPickerActive]
  );

  // return locale context provider
  return (
    <LocaleContext.Provider value={memoizedValue}>
      {children}
    </LocaleContext.Provider>
  );
}

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
  allowedCurrencies: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export { LocaleProvider, LocaleContext };
