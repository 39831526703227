import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import retry from 'async-retry';
import { Buffer } from 'buffer';
import { GraphQLClient } from 'graphql-request';

import getCookieDomain from '@rsc/utils/getCookieDomain';
import { pushToDataLayer } from '@rsc/utils';
import { readBrowserStorage, writeBrowserStorage } from './storageUtil';
import {
  CREATE_CART_QUERY,
  GET_CART_QUERY,
  ADD_LINE_ITEM_QUERY,
  REMOVE_LINE_ITEM_QUERY,
  UPDATE_LINE_ITEM_QUERY,
  UPDATE_CART_ATTRIBUTES_QUERY,
} from './cartQueries';
import { LocaleContext } from './LocaleContext';

const defaultState = {
  addToCart: () => {},
  updateLineItems: () => {},
  incrementLineItem: () => {},
  decrementLineItem: () => {},
  emptyCart: () => {},
  deleteLineItem: () => {},
  setCartActive: () => {},
  hideToastNotification: () => {},
  hideToast: () => {},
  cart: {},
  cartActive: false,
  cartBusy: false,
  cartError: false,
  showToast: false,
  toastProduct: null,
  toastAddOns: [],
  updateCartAttributes: () => {},
  shopifyClient: () => {},
  formatPrice: () => {},
};

const USD_CART_KEY_IDENTIFIER = '_cartId';
const CAD_CART_KEY_IDENTIFIER = '_cadCartId';
const SUBSCRIPTION_FREQUENCY_METAFIELD = 'subscription_frequency';

const CartContext = createContext(defaultState);

function CartProvider({ children }) {
  const localeContext = useContext(LocaleContext);
  const currency = localeContext?.currency || 'USD';
  const [cart, setCart] = useState(defaultState.cart);
  const [cartActive, setCartActive] = useState(defaultState.cartActive);
  const [cartBusy, setCartBusy] = useState(defaultState.cartBusy);
  const [cartError, setCartError] = useState(defaultState.cartError);
  const [showToast, setShowToast] = useState(defaultState.showToast);
  const [toastProduct, setToastProduct] = useState(defaultState.toastProduct);
  const [toastAddOns, setToastAddOns] = useState(defaultState.toastAddOns);
  const [shopifyClient, setShopifyClient] = useState(
    currency === 'CAD'
      ? new GraphQLClient(
          `https://${process.env.GATSBY_SHOPIFY_CAD_DOMAIN_NAME}/api/2023-04/graphql.json`,
          {
            headers: {
              'X-Shopify-Storefront-Access-Token':
                process.env.GATSBY_SHOPIFY_CAD_ACCESS_TOKEN,
            },
          }
        )
      : new GraphQLClient(
          `https://${process.env.GATSBY_SHOPIFY_DOMAIN_NAME}/api/2023-04/graphql.json`,
          {
            headers: {
              'X-Shopify-Storefront-Access-Token':
                process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
            },
          }
        )
  );

  const setCartActiveHandler = activeState => {
    setCartActive(activeState);
    setShowToast(false);
  };

  const createNewCart = async client => {
    try {
      const newCart = await client.request(CREATE_CART_QUERY, {
        input: {
          attributes: process.env.GATSBY_RSC_ENV
            ? [
                {
                  key: 'rscEnv',
                  value: process.env.GATSBY_RSC_ENV,
                },
              ]
            : [],
          buyerIdentity: {
            countryCode: localeContext?.getCountryCode() || 'US',
          },
        },
      });
      writeBrowserStorage(
        currency === 'CAD' ? CAD_CART_KEY_IDENTIFIER : USD_CART_KEY_IDENTIFIER,
        newCart?.cartCreate?.cart?.id || '',
        getCookieDomain()
      );
      return newCart?.cartCreate?.cart;
    } catch (error) {
      console.error('createNewCart error: ', error);
    }

    console.error('Something went wrong when trying to create new cart.');
    return null;
  };

  const initializeCart = async client => {
    const cartId = readBrowserStorage(
      currency === 'CAD' ? CAD_CART_KEY_IDENTIFIER : USD_CART_KEY_IDENTIFIER
    );

    if (cartId) {
      const existingCart = await client
        .request(GET_CART_QUERY, {
          id: cartId,
        })
        .catch(() => {
          // Couldn't retrieve cart based on cookie/localStorage _cartId
          // so create a fresh one
          console.warn(
            'Could not retrieve cart based on stored ID, creating a new cart.'
          );
          return createNewCart(client);
        });

      // Is existing cart stale?
      if (!existingCart?.cart) {
        console.warn(
          'Fetch cart call returned null value (stale cart). Creating new cart.'
        );
        return createNewCart(client);
      }

      return existingCart.cart;
    }

    console.info(
      'Could not retrieve cart based on stored ID, created a new cart'
    );
    return createNewCart(client);
  };

  const updateCart = async newCart => {
    if (
      typeof newCart !== 'undefined' &&
      typeof newCart === 'object' &&
      Object.prototype.hasOwnProperty.call(newCart, 'id')
    ) {
      setCart(newCart);
    }
  };

  const updateLineItems = async lineItemsToUpdate => {
    setCartBusy(true);

    try {
      const newCartUpdateLine = await shopifyClient.request(
        UPDATE_LINE_ITEM_QUERY,
        {
          cartId: cart.id,
          lines: lineItemsToUpdate,
        }
      );

      await updateCart(newCartUpdateLine.cartLinesUpdate.cart);

      setCart(newCartUpdateLine.cartLinesUpdate.cart);
      setCartBusy(false);
    } catch (error) {
      console.error('updateLineItem error: ', lineItemsToUpdate, error);
      setCartBusy(false);
    }
  };

  /**
   * Adds product to cart. Repeated adds increment quantity.
   */
  const addToCart = async ({
    activeVariant,
    addOns,
    product,
    quantity,
    selectedSellingPlan,
    sellingPlans,
    variantId,
    variantMetafields,
  }) => {
    setCartBusy(true);

    try {
      const lineItem = {
        quantity,
        merchandiseId: Buffer.from(variantId).toString('base64'),
        attributes: [],
      };

      // IFB subscriptions
      const productDeliveryFrequency = variantMetafields?.find(
        metafield =>
          metafield.name === SUBSCRIPTION_FREQUENCY_METAFIELD ||
          metafield.key === SUBSCRIPTION_FREQUENCY_METAFIELD
      )?.value;

      if (productDeliveryFrequency) {
        const sellingPlanId = sellingPlans.find(
          sellingPlan =>
            sellingPlan.name ===
            `Delivery every ${productDeliveryFrequency} Months`
        );

        if (sellingPlanId) {
          lineItem.sellingPlanId = Buffer.from(sellingPlanId.id).toString(
            'base64'
          );
        }
      }

      // PetSafe subscriptions
      if (selectedSellingPlan?.id) {
        lineItem.sellingPlanId = Buffer.from(selectedSellingPlan.id).toString(
          'base64'
        );
        if (selectedSellingPlan.name) {
          lineItem.attributes.push({
            key: 'Auto-renews',
            value: selectedSellingPlan.name,
          });
        }

        if (selectedSellingPlan.percentageDiscount) {
          lineItem.attributes.push({
            key: 'Subscription Discount Applied',
            value: `${selectedSellingPlan.percentageDiscount}%`,
          });
        }
      }

      if (productDeliveryFrequency) {
        lineItem.attributes.push({
          key: 'Auto-renews',
          value: 'every 12 Months',
        });
      }

      const cartId = readBrowserStorage(
        currency === 'CAD' ? CAD_CART_KEY_IDENTIFIER : USD_CART_KEY_IDENTIFIER
      );

      setToastAddOns([]);
      let addOnLineItems = [];
      if (addOns?.length > 0) {
        addOnLineItems = addOns.map(({ cartProps }) => ({
          quantity: 1,
          merchandiseId: Buffer.from(cartProps.variantId).toString('base64'),
          attributes: [],
        }));
        setToastAddOns(addOns);
      }

      const newCartAddLine = await shopifyClient.request(ADD_LINE_ITEM_QUERY, {
        cartId,
        lines: [lineItem, ...addOnLineItems],
      });

      let addedLineItem = newCartAddLine.cartLinesAdd.cart?.lines.edges.find(
        ({ node }) =>
          node.merchandise.id === variantId &&
          node?.sellingPlanAllocation?.sellingPlan?.id ===
            selectedSellingPlan?.id
      );

      // Try to find line item without selectedSellingPlan
      if (!addedLineItem) {
        addedLineItem = newCartAddLine.cartLinesAdd.cart?.lines.edges.find(
          ({ node }) => node.merchandise.id === variantId
        );
      }

      await updateCart(newCartAddLine.cartLinesAdd.cart);

      const discounts = addedLineItem.node.discountAllocations ?? [];

      // Add a line item for applied promotions, if they exist...KA
      if (discounts.length) {
        await updateLineItems([
          {
            id: addedLineItem.node.id,
            attributes: [
              ...addedLineItem.node.attributes,
              {
                key: 'Promotions Applied',
                value: discounts.map(d => d.title).join(', '),
              },
            ],
          },
        ]);
      }

      setCart(newCartAddLine.cartLinesAdd.cart);

      setCartBusy(false);
      setShowToast(true);
      setToastProduct(addedLineItem.node);

      const klaviyoItem = {
        ProductName: product?.shopifyProduct?.title,
        shopifyId: product?.shopifyProduct?.shopifyId,
        SKU: activeVariant?.sku,
        ImageURL: activeVariant?.media[0]?.image?.src,
        Brand: product?.shopifyProduct?.vendor,
        Price: activeVariant?.price,
        URL: window.location.href,
      };

      window?.klaviyo?.push(['track', 'Added to cart', klaviyoItem]);
    } catch (error) {
      console.error('addToCart had error: ', error);
      setCartBusy(false);
    }
  };

  const findLineItem = lineItemId =>
    cart.lines.edges.find(({ node }) => node.id === lineItemId);

  const incrementLineItem = async lineItemId => {
    const lineItem = findLineItem(lineItemId);

    if (lineItem) {
      updateLineItems([
        { id: lineItem.node.id, quantity: lineItem.node.quantity + 1 },
      ]);

      const lineItemPrice = lineItem?.node?.merchandise?.price?.amount || 0;

      pushToDataLayer('add_to_cart', {
        ecommerce: {
          currency,
          value: Number(lineItemPrice).toFixed(2),
        },
        items: [
          {
            variant: lineItem?.node?.merchandise,
            product: lineItem?.node?.merchandise?.product,
            quantity: 1,
          },
        ],
      });
    }
  };

  /**
   * Decrease qty of lineItem. Will remove lineItem if qty is 0
   */
  const decrementLineItem = async lineItemId => {
    const lineItem = findLineItem(lineItemId);

    if (lineItem) {
      const removedLinePrice = lineItem?.node?.merchandise?.price?.amount || 0;

      pushToDataLayer('remove_from_cart', {
        ecommerce: {
          currency,
          value: Number(removedLinePrice).toFixed(2),
        },
        items: [
          {
            variant: lineItem?.node?.merchandise,
            product: lineItem?.node?.merchandise?.product,
            quantity: 1,
          },
        ],
      });

      updateLineItems([
        { id: lineItem.node.id, quantity: lineItem.node.quantity - 1 },
      ]);
    }
  };

  const emptyCart = async () => {
    setCartBusy(true);

    try {
      const allLineItemIds = cart.lines.edges.map(({ node }) => node.id);

      const newCartRemoveLine = await shopifyClient.request(
        REMOVE_LINE_ITEM_QUERY,
        {
          cartId: cart.id,
          lineIds: allLineItemIds,
        }
      );

      await updateCart(newCartRemoveLine.cartLinesRemove.cart);
      setCart(newCartRemoveLine.cartLinesRemove.cart);
      setCartBusy(false);
    } catch (error) {
      console.error('emptyCart error: ', error);
      setCartBusy(false);
    }
  };

  const deleteLineItem = async lineItemId => {
    setCartBusy(true);

    try {
      const newCartRemoveLine = await shopifyClient.request(
        REMOVE_LINE_ITEM_QUERY,
        {
          cartId: cart.id,
          lineIds: [lineItemId],
        }
      );

      const lineItem = findLineItem(lineItemId);
      const removedLinePrice = lineItem?.node?.merchandise?.price?.amount || 0;
      const removedLineQuantity = lineItem?.node?.quantity || 0;
      const totalPrice = removedLinePrice * removedLineQuantity;

      pushToDataLayer('remove_from_cart', {
        ecommerce: {
          currency,
          value: totalPrice.toFixed(2),
        },
        items: [
          {
            variant: lineItem?.node?.merchandise,
            product: lineItem?.node?.merchandise?.product,
            quantity: removedLineQuantity,
          },
        ],
      });

      await updateCart(newCartRemoveLine.cartLinesRemove.cart);
      setCart(newCartRemoveLine.cartLinesRemove.cart);

      setCartBusy(false);
    } catch (error) {
      console.error('removeLineItem error: ', error);
      setCartBusy(false);
    }
  };

  const hideToastNotification = () => {
    setShowToast(false);
    setToastProduct(null);
  };

  const writePepperjamData = () => {
    if (readBrowserStorage('pjnClickData')) {
      const pepperjamData = readBrowserStorage('pjnClickData');

      // duplicate pepperjam cookie and set domain where it can cross subdomains
      writeBrowserStorage('pjn-click', pepperjamData, getCookieDomain());
    }
  };

  const updateShopifyClient = () => {
    let client;

    client = new GraphQLClient(
      `https://${process.env.GATSBY_SHOPIFY_DOMAIN_NAME}/api/2023-04/graphql.json`,
      {
        headers: {
          'X-Shopify-Storefront-Access-Token':
            process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
        },
      }
    );

    if (currency === 'CAD') {
      client = new GraphQLClient(
        `https://${process.env.GATSBY_SHOPIFY_CAD_DOMAIN_NAME}/api/2023-04/graphql.json`,
        {
          headers: {
            'X-Shopify-Storefront-Access-Token':
              process.env.GATSBY_SHOPIFY_CAD_ACCESS_TOKEN,
          },
        }
      );
    }

    setShopifyClient(client);
    return client;
  };

  const updateCartAttributes = async attributes => {
    if (attributes) {
      try {
        const cartId = readBrowserStorage(
          currency === 'CAD' ? CAD_CART_KEY_IDENTIFIER : USD_CART_KEY_IDENTIFIER
        );

        const updatedCart = await shopifyClient.request(
          UPDATE_CART_ATTRIBUTES_QUERY,
          {
            cartId,
            attributes,
          }
        );

        if (updatedCart?.cartAttributesUpdate?.cart) {
          setCart(updatedCart.cartAttributesUpdate.cart);
        }
      } catch (err) {
        console.error('updateCartAttributes error: ', err);
      }
      setCartBusy(false);
    }
  };

  const formatPrice = price =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(price);

  useEffect(() => {
    (async () => {
      try {
        await retry(
          async () => {
            const client = updateShopifyClient();
            const initCart = await initializeCart(client);
            await updateCart(initCart);
            writePepperjamData();
          },
          {
            maxTimeout: 1000,
            retries: 3,
          }
        );
      } catch (error) {
        console.error('Cart could not be created.', error);
        setCartError(true);
      }
    })();
  }, [currency]);

  const value = useMemo(
    () => ({
      cart,
      cartActive,
      cartBusy,
      cartError,
      deleteLineItem,
      addToCart,
      emptyCart,
      updateLineItems,
      incrementLineItem,
      decrementLineItem,
      setCartActive: setCartActiveHandler,
      showToast,
      hideToast: hideToastNotification,
      toastProduct,
      toastAddOns,
      updateCartAttributes,
      shopifyClient,
      formatPrice,
    }),
    [cart, cartActive, cartBusy, cartError, showToast, shopifyClient]
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CartContext, CartProvider };
