import { DefaultTheme } from '@rsc/ui-components/theme';
import styles from './utils/styles';
import themeColors from './themeColors';
import logo from '../images/logo.svg';

// use shared template and extend/override with custom theme values

const theme = {
  ...DefaultTheme,
  button: {
    ...DefaultTheme.button,
    primary: {
      ...DefaultTheme.button.primary,
      background: styles.colors.blue[500],
      backgroundActive: styles.colors.blue[700],
      backgroundHover: styles.colors.blue[600],
      backgroundDisabled: styles.colors.blue[600],
      borderColor: styles.colors.blue[600],
    },
    secondary: {
      ...DefaultTheme.button.secondary,
      background: styles.colors.blue[500],
      backgroundActive: styles.colors.blue[700],
      backgroundHover: styles.colors.blue[600],
      backgroundDisabled: styles.colors.blue[600],
      borderColor: styles.colors.blue[600],
    },
    tertiary: {
      ...DefaultTheme.button.tertiary,
      background: styles.colors.blue[500],
      backgroundActive: styles.colors.blue[700],
      backgroundHover: styles.colors.blue[600],
      backgroundDisabled: styles.colors.blue[600],
      borderColor: styles.colors.blue[600],
    },
  },
  colors: {
    ...DefaultTheme.colors,
    ...themeColors,
  },
  hero: {
    fontColor: 'white',
    span: {
      textTransform: 'none',
    },
  },
  images: {
    logo,
  },
  name: 'Kurgo Theme',
  notificationBanner: {
    background: styles.colors.blue[500],
  },
  productCard: {
    ...DefaultTheme.productCard,
    titleColor: styles.colors.gray[700],
    inlineRatingsMarginBottom: '0.625rem',
  },
  quizCard: {
    recommendationBackgroundColor: 'none',
  },
};

export default theme;
